import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

function Merchant() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const iframeRef = useRef(null);

  const decodedUrl = decodeURIComponent(params.get("link"));
  console.log(decodedUrl);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
      <iframe ref={iframeRef} id="gameFrame" src={decodedUrl} allowFullScreen webkitallowfullscreen style={{ height: "100vh", height: "100dvh", width: "100vw", width: "100dvw" }}></iframe>
    </div>
  );
}

export default Merchant;
